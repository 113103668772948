
const filter: any = {
  // 格式化OSS图片地址，为OSS图片添加域名
  // ossImgDomain：https://static.buff8.com/H5/app/
  // path 不需再带 /
  addOssDomainToPath (path: string) {
    return `https://static.buff8.com/hzzn_group_web/${path}`
  },
  // 过万保留两位小数
  countUnit (count: number) {
    if (!count) {
      return 0
    }
    if (count < 10000) {
      return count
    } else if (count >= 10000) {
      const str = (count / 10000).toString()
      const arr = str.split('.')
      const num = str.includes('.') ? arr[1].length === 1 ? str + '0' : arr[1].length === 2 ? str : arr[0] + '.' + arr[1].substring(0, 2) : str + '.00'
      return num + 'w'
    }
  }
}
export default filter
